<template>
  <div>
    <a-row :gutter="24" type="flex">

      <!-- Projects Table Column -->
      <a-col :span="24" class="mb-24">

        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12" class="top-left-header">
                <h5 class="font-semibold m-0">Astrologer List<a-spin v-if="confirmLoading"/></h5>
              </a-col>
            </a-row>
          </template>
          <a-table :columns="columnData"
                   :data-source="astrologerList"
                   :pagination="false"
                   :scroll="{x: true}">

            <template slot="name" slot-scope="text">
              <a>{{ text }}</a>
            </template>
            <template slot="profileImage" slot-scope="row">
              <img :src="row.image" :alt="row.name" width="100" height="100">
            </template>
            <template slot="uploadBtn" slot-scope="row">
              <div>
                <a-button type="link" :data-id="row.key">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                       @click="showUpdateModal(row.details)">
                    <path class="fill-gray-7"
                          d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"/>
                    <path class="fill-gray-7"
                          d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"/>
                  </svg>
                </a-button>
              </div>
            </template>
          </a-table>
        </a-card>

      </a-col>
      <!-- / Projects Table Column -->

<!--      Update Profile Image-->
      <a-modal
          v-model:updateModal="updateModal"
          title="Astrologer Profile Update"
          :confirm-loading="confirmLoading"
          @ok="closeUpdateModal"
      >
        <div>
          <label>Astrologer Name</label>
          <a-input v-model="astrologerName" placeholder="Astrologer Name"/>
        </div>
        <div>
          <label>Astrologer Designation</label>
          <a-input v-model="astrologerDesignation" placeholder="Astrologer Designation"/>
        </div>
        <div>
          <label>Astrologer Image File Upload</label>
          <a-input type="file" @change="onFileChange" ref="uploadImage"
                   placeholder="Basic usage"
                   accept=""/>
        </div>
        <div v-if="errorMsg.length" class="ant-btn-danger mt-5">{{ errorMsg }}</div>
      </a-modal>

    </a-row>
  </div>
</template>

<script>
import {sendRequest} from '@/http/axios.method';

const COLUMN_DATA = [
  {
    title: 'Astrologer Name',
    dataIndex: 'name',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Designation',
    dataIndex: 'designation',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Profile Image',
    scopedSlots: {customRender: 'profileImage'},
  },
  {
    title: '',
    scopedSlots: {customRender: 'uploadBtn'},
    width: 200,
  },
];
export default {
  name: "Astrologer",
  data() {
    return {
      config: {
        'Authorization': 'Bearer ' + this.$store.state.authUser.token
      },
      columnData: COLUMN_DATA,
      astrologerData: [],
      astrologers: [],
      startDate: '',
      endDate: '',
      dateRange: [],
      confirmLoading: false,
      updateModal: false,
      astrologerId: '',
      astrologerName: '',
      astrologerDesignation: '',
      fileContent: '',
      errorMsg: '',
    }
  },
  created() {
    this.getAstrologerList()
  },
  methods: {
    getAstrologerList() {
      this.confirmLoading = true
      sendRequest('get', 'astrologers', {} , this.config)
          .then(response => {
            this.confirmLoading = false
            if (response.success) {
              this.astrologers = response.data.astrologers
              this.astrologerData = this.astrologers.map(function (astrologer, label) {
                return {
                  key: label + 1,
                  name: astrologer.name,
                  designation: astrologer.designation,
                  image: astrologer.profile_image,
                  details: astrologer
                };
              });
            } else {
              if (response.status_code === 401) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
              }
              if (response.status_code === 500) {
                this.revenueReportData = [];
              }
            }
          })
          .catch(() => {
            this.confirmLoading = false
          })
          .finally(() => {
            this.confirmLoading = false
          })
    },
    showUpdateModal(astrologer) {
      this.astrologerId = astrologer.id
      this.astrologerName = astrologer.name
      this.astrologerDesignation = astrologer.designation
      console.log('current_astrologer', astrologer)
      this.updateModal = true
    },
    onFileChange(e) {
      this.errorMsg = '';
      this.fileContent = e.target.files[0];
    },
    closeUpdateModal() {
      this.confirmLoading = true;
      this.errorMsg = '';
      if (this.astrologerName.length === 0) {
        this.errorMsg = 'Please provide astrologer name';
        return;
      }
      if (this.astrologerDesignation.length === 0) {
        this.errorMsg = 'Please provide astrologer designation';
        return;
      }
      let formData = new FormData();
      let configHeader = {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' + this.$store.state.authUser.token
      }
      formData.append('astrologer_id', this.astrologerId);
      formData.append('astrologer_name', this.astrologerName);
      formData.append('astrologer_designation', this.astrologerDesignation);
      formData.append('profile_image', this.fileContent);

      sendRequest('post', 'admin/astrologer-profile-update', formData, configHeader)
          .then(response => {
            if (response.success) {
              this.confirmLoading = false;
              this.fileContent = '';
              this.$refs.uploadImage.value = null;
              this.astrologerId = '';
              this.astrologerName = '';
              this.astrologerDesignation = '';
              this.updateModal = false;
              this.getAstrologerList()
            } else {
              if (response.status_code === 401) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
              }
            }

          })
          .catch(() => {
            this.confirmLoading = false;
          })
          .finally(() => {
            this.confirmLoading = false;
          })
    }
  },
  computed: {
    astrologerList: function () {
      return this.astrologerData;
    }
  }
}
</script>

<style scoped>

</style>
